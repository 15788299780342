import { useTranslation } from "react-i18next";
import DataTable from "../DataTable";
import { useEffect, useState } from "react";
import { getEvents, getPosters, getProfiles } from "../../../../service/api";


export default function Events() {
    const { t } = useTranslation();
    const [rows, setRows] = useState()
    const [total, setTotal] = useState(1)
    const [limit, setLimit] = useState(25)
    const [offset, setOffset] = useState(0)

    const refresh = () => {
        getEvents({limit, offset})
            .then(r => {
                setRows(r.eventDtos.map(p => ({ ...p, createdAt: new Date(p.createdAt).toLocaleString() })))
                setTotal(r.total)
            })
    }

    useEffect(() => {
        refresh()
    }, [offset])

    useEffect(() => {
        if (offset === 0) {
            refresh()
        } else {
            setOffset(0)
        }
    }, [limit])

    const tableConfig = [
        { id: 'id', label: t('admin.events.table.id'), minWidth: 100 },
        { id: 'ownerUsername', label: t('admin.events.table.owner_username'), minWidth: 100 },
        { id: 'ownerId', label: t('admin.events.table.owner_id'), minWidth: 100 },
        { id: 'createdAt', label: t('admin.events.table.created_at'), minWidth: 100 }
    ];

    return (
        <DataTable
            config={tableConfig}
            rows={rows}
            total={total}
            limit={limit}
            setLimit={setLimit}
            page={offset / limit}
            setPage={(page) => setOffset(page * limit)}
            click
        />
    )
}
import {
    Box,
    Button,
    ButtonBase,
    Container,
    Divider,
    Hidden,
    Modal,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import styles from "./PrivacyPolicy.module.css";
import React, {createRef, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import privacy_en from "../../pdfs/privacy_en.pdf";
import privacy_ru from "../../pdfs/privacy_ru.pdf";
import terms_en from "../../pdfs/terms_en.pdf";
import terms_ru from "../../pdfs/terms_ru.pdf";
import Feedback from "../Main/Feedback";
import styled from "@emotion/styled";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Document, Page} from "react-pdf";


const FlexBox = styled.div`
  display: ${({ display }) => display || "flex"};
  flex-direction: ${({ fDirection }) => fDirection || "row"};
  flex-wrap: ${({ wrap }) => wrap || "wrap"};
  align-items: ${({ align }) => align || "center"};
  justify-content: ${({ justify }) => justify || "center"};
  padding: ${({ padding }) => padding || "0"};
  margin: ${({ margin }) => margin || "0"};
  width: ${({ width }) => width || "auto"};
`;

export default function PrivacyPolicy() {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [isActive, setIsActive] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const lang = searchParams.get("lang")
    const [modal, setModal] = useState()
    const [numPages, setNumPages] = useState();
    const [curPage, setCurPage] = useState(1);
    const { width } = window.innerWidth;
    const { t, i18n } = useTranslation()
    const navigate = useNavigate();
    let titles = [
        'privacy_policy.where_this_applies',
        'privacy_policy.information_we_collect',
        'privacy_policy.other_information_with_consent',
        'privacy_policy.cookies_and_similar_data_collection_technologies',
        'privacy_policy.how_we_use_information',
        'privacy_policy.how_we_share_information',
        'privacy_policy.cross_border_data_transfers',
        'privacy_policy.your_rights',
        'privacy_policy.residents_of_california',
        'privacy_policy.how_we_protect_your_information',
        'privacy_policy.how_long_we_retain_your_information',
        'privacy_policy.accessing_the_service',
        'privacy_policy.childrens_privacy',
        'privacy_policy.what_you_may_and_may_not_do_on_the_service',
        'privacy_policy.uploaded_content_and_content_standards',
        'privacy_policy.disclaimers',
        'privacy_policy.privacy_policy_changes',
        'privacy_policy.how_to_contact_us'
    ];

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const [heights, setHeights] = useState([]);
    const elementsRef = useRef(titles.map(() => createRef()));

    useEffect(() => {
        const nextHeights = elementsRef.current.map((ref) => ref);
        setHeights(nextHeights);
    }, []);

    useLayoutEffect(()=>{
        if(lang) {
            i18n.changeLanguage(lang)
        }
    }, [lang])
    const scrollTo = (i) => {
        setIsActive(i);
        console.log(heights)
        if (heights[i] && heights[i].current) {
            heights[i].current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    };
    return (
        <Box className={styles.background}>
            <Container maxWidth={"xl"}>
                <Stack justifyContent={"space-between"} minHeight={'100vh'}>
                    <Box>
                        <Box pt={isSmallScreen ? 4 : '60px'}/>
                        <Stack direction={isSmallScreen ? 'column' : "row"} alignItems={'center'} spacing={2}
                               component={'header'}>
                            <img src="/mapllo.svg" alt="Mapllo Logo" width={207} height={68}
                                 onClick={() => navigate('/')} style={{cursor: 'pointer'}}/>
                            <Hidden mdDown>
                                <Divider orientation={'vertical'} variant={'middle'} flexItem={true}/>
                            </Hidden>
                            <Box width={'100%'} flexDirection={'row'} display={'flex'}
                                 justifyContent={isSmallScreen ? 'center' : 'flex-start'}>
                                <Button variant={isSmallScreen ? 'outlined' : 'text'}
                                        color={i18n.language === 'en' ? "primary" : "disabled"}
                                        onClick={() => i18n.changeLanguage('en')}>ENG</Button>
                                {isSmallScreen && <Box style={{width: '10px'}}/>}
                                <Button variant={isSmallScreen ? 'outlined' : 'text'}
                                        color={i18n.language === 'ru' ? "primary" : "disabled"}
                                        onClick={() => i18n.changeLanguage('ru')}>RU</Button>
                            </Box>
                        </Stack>
                        <Box mt={isSmallScreen ? '1rem' : '60px'}>
                            <FlexBox
                                width={"100%"}
                                wrap={"nowrap"}
                                align={"flex-start"}
                                justify={width > 1000 ? "space-between" : "flex-start"}
                            >
                                <FlexBox width={"770px"}>
                                    {[...titles].map((title, i) => (
                                        <Box
                                            justify={"flex-start"}
                                            align={"flex-start"}
                                            padding={width < 640 ? "15px" : "45px"}
                                            backgroundColor={"#FFFFFF"}
                                            borderRadius={"12px"}
                                            margin={"0 0 30px 0"}
                                            flexDirection={"column"}
                                        >
                                            <div ref={elementsRef.current[i]}/>
                                            <Typography fontW={700} variant={"h4"}>
                                                {t(title)}
                                            </Typography>
                                            <Typography
                                                margin={"30px 0 0 0"}
                                                size={"14px"}
                                                variant={"p"}
                                                dangerouslySetInnerHTML={{
                                                    __html: t(`${title}.description`, {
                                                        interpolation: {escapeValue: false},
                                                    }),
                                                }}
                                            />
                                        </Box>
                                    ))}
                                </FlexBox>
                                <Box
                                    position={"sticky"}
                                    top={"10px"}
                                    align={"flex-start"}
                                    flexDirection={"column"}
                                    margin={"0 10px 0 30px"}
                                    sx={{
                                        display: {xs: 'none', sm: 'none', md: 'flex'},
                                        '&:hover': {
                                            cursor: 'pointer'
                                        }
                                    }}>
                                    {[...titles].map((title, i) => (
                                        <Typography
                                            key={i}
                                            onClick={() => scrollTo(i)}
                                            space={"nowrap"}
                                            variant={"h7"}
                                            margin={"0 0 10px 0"}
                                            color={isActive === i ? "#A347FF" : "#848484"}
                                            borderBottom={isActive === i ? "1px solid #A347FF" : "0"}
                                        >
                                            {" " + t(title)}
                                        </Typography>
                                    ))}
                                </Box>
                            </FlexBox>
                        </Box>
                        <Stack direction={"row"} spacing={isSmallScreen ? 0 : 4} alignItems={'center'}
                               justifyContent={isSmallScreen ? 'space-between' : 'flex-start'} component={'div'}>
                            <img src="/mapllo_app.svg" alt="Mapllo App Logo" width={isSmallScreen ? 57 : 71}
                                 height={isSmallScreen ? 57 : 71}/>
                            <a href={"https://apps.apple.com/app/mapllo-%D0%B7%D0%BD%D0%B0%D0%BA%D0%BE%D0%BC%D1%81%D1%82%D0%B2%D0%B0-%D0%B8-%D0%B4%D0%BE%D1%81%D1%83%D0%B3/id1579691867"}
                               style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                <img src="/AppStore.svg" alt="App Store Logo" width={isSmallScreen ? 80 : 136}
                                     height={isSmallScreen ? 36 : 44}/>
                            </a>
                            <a href={"https://play.google.com/store/apps/details?id=com.production.mapllo"}
                               style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                <img src="/GooglePlay.svg" alt="Google Play Logo" width={isSmallScreen ? 80 : 136}
                                     height={isSmallScreen ? 36 : 44}/>
                            </a>
                            <a href={"https://appgallery.huawei.com/app/C109416847"}
                               style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                <img src="/huawei.svg" alt="Huawei Logo" width={isSmallScreen ? 80 : 136}
                                     height={isSmallScreen ? 36 : 44}/>
                            </a>

                        </Stack>
                    </Box>
                    <Divider
                        style={{marginBottom: '10px', marginTop: '20px', width: isSmallScreen ? '100%' : '632px'}}/>
                    <Stack component={"footer"} direction={'row'} mb={2}
                           justifyContent={isSmallScreen ? 'space-between' : 'flex-start'}>
                        <Box>
                            <Typography mt={'2rem'} color={'#818195'} whiteSpace={'pre-wrap'} component={'span'}
                                        fontSize={isSmallScreen ? '10px' : '14px'}>
                                {t('copyright', {year: new Date().getFullYear()})}{isSmallScreen && '\n'}
                            </Typography>

                            <Typography color={'#818195'} whiteSpace={'pre-wrap'} component={'span'}
                                        fontSize={isSmallScreen ? '10px' : '14px'}>
                                {t('rights_reserved')}{' | '}
                            </Typography>
                            <ButtonBase onClick={() => scrollTo(0)}>
                                <Typography component={'span'} color={'#818195'}
                                            fontSize={isSmallScreen ? '10px' : '14px'}
                                            textAlign={'left'}>{t('privacy_policy')}</Typography>
                            </ButtonBase>
                            <Typography color={'#818195'} whiteSpace={'pre-wrap'} component={'span'}
                                        fontSize={isSmallScreen ? '10px' : '14px'}>
                                {' | '}
                            </Typography>
                            <ButtonBase onClick={() => setModal(i18n.language == 'en' ? terms_en : terms_ru)}>
                                <Typography component={'span'} color={'#818195'}
                                            fontSize={isSmallScreen ? '10px' : '14px'}
                                            textAlign={'left'}>{t('terms')}</Typography>
                            </ButtonBase>
                        </Box>
                        <Feedback fontSize={'11px'} sx={[isSmallScreen ? {bottom: '30px', right: '20px'} : {
                            right: '100px',
                            top: '80px',
                            position: 'absolute'
                        }]}/>
                    </Stack>
                </Stack>
            </Container>
            <Modal
                open={!!modal}
                onClose={() => {setModal(null); setCurPage(1);}} style={{ justifyContent: 'center', display: 'flex' }}>
                <Box width={'fit-content'} position={'relative'}>
                    <Document file={modal} loading={'Loading'} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
                        <Page pageNumber={curPage} renderTextLayer={false} renderAnnotationLayer={false}/>
                        <Box position={'absolute'} bottom={'20px'} flexDirection={'row'} display={'flex'} justifyContent={'space-around'} width={'100%'}>
                            <ButtonBase onClick={() => setCurPage(Math.max(1, curPage - 1))}><Typography fontSize={40} fontWeight={600} color={'rgba(0,0,0,0.5)'} width='50px'>{'<'}</Typography></ButtonBase>
                            <ButtonBase onClick={() => setCurPage(Math.min(numPages, curPage + 1))}><Typography fontSize={40} fontWeight={600} color={'rgba(0,0,0,0.5)'} width='50px'>{'>'}</Typography></ButtonBase>
                        </Box>
                    </Document>
                </Box>
            </Modal>
        </Box>
    );
}

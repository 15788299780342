import { apiUrl, authUrl } from "../constants";


export async function getProfile({ id }) {
  const result = await fetch(apiUrl + `/profiles/${id}`, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  const jsonResult = await result.json()
  return jsonResult
}

export async function getEvent({ id }) {
  const result = await fetch(apiUrl + `/events/${id}`, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  const jsonResult = await result.json()
  return jsonResult
}

export async function getEvents({ offset, limit }) {
  const result = await fetch(apiUrl + `/events?limit=${limit}&offset=${offset}`, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  const jsonResult = await result.json()
  return jsonResult
}

export async function getPosters() {
  const result = await fetch(apiUrl + `/aggregated/posters`, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  const jsonResult = await result.json()
  return jsonResult
}

export async function createPoster(body) {
  return await fetch(apiUrl + `/posters`, {
    method: 'POST',
    body: body,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
}

export async function createBot(body) {
  return await fetch(apiUrl + `/profiles/bot`, {
    method: 'POST',
    body: body,
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
}


export async function deletePoster(id) {
  return await fetch(apiUrl + `/posters/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
}

export async function blockProfileAuth({ id, block }) {
  const result = await fetch(authUrl + `/user/${id}/block?blocked=${block}`, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
}

export async function blockProfile({ id, block }) {
  const result = await fetch(apiUrl + `/profile/${id}/block?block=${block}`, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  const jsonResult = await result.json()
  return jsonResult
}

export async function getProfiles({ offset, limit }) {
  const result = await fetch(apiUrl + `/profiles?limit=${limit}&offset=${offset}`, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  const jsonResult = await result.json()
  return jsonResult
}

export async function getComplains({ offset, limit }) {
  const result = await fetch(apiUrl + `/complains?limit=${limit}&offset=${offset}`, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  const jsonResult = await result.json()
  return jsonResult
}

export async function getOwn() {
  const result = await fetch(authUrl + '/own', {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  const jsonResult = await result.json()
  return jsonResult
}

export async function googleAuth(jwt) {
  const result = await fetch(authUrl + '/login/google', { method: 'POST', body: jwt })
  const jsonResult = await result.json()
  return jsonResult
}


export async function complain(message) {
  return await fetch(apiUrl + '/complains', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }, body: JSON.stringify(message)
  })
}

import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import Main from "./pages/Main";
import Admin from "./pages/Admin";
import {createTheme, ThemeProvider} from "@mui/material";
import NoAccess from "./pages/Admin/noaccess";
import Auth from "./pages/Admin/auth";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {UserContextProvider} from "./context/user";
import User from "./pages/Share/User";
import Event from "./pages/Share/Event";
import Poster from "./pages/Share/Poster";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from "@mui/x-date-pickers";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  const maplloTheme = createTheme({
    typography: {
      fontFamily: 'Montserrat'
    },
    palette: {
      primary: {
        main: '#A347FF',
      },
      secondary: {
        main: '#F3267D',
      },
      gray: {
        main: '#818195'
      },
      disabled: {
        main: '#2C2C2C'
      },
    }
  });

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Main/>} />
        <Route path="/admin/noaccess" element={<NoAccess/>}/>
        <Route path="/admin/auth" element={<Auth/>}/>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
        <Route path="/admin" element={<Admin/>}/>
        <Route path="/admin/:tabParam" element={<Admin/>}/>
        <Route path="/admin/:tabParam/:idParam" element={<Admin/>}/>
        <Route path="/users/:id" element={<User/>} />
        <Route path="/events/:id" element={<Event/>} />
        <Route path="/posters/:id" element={<Poster/>} />
      </>
    )
  );

  return (
    <div style={{minHeight: '100vh'}}>
      <ThemeProvider theme={maplloTheme}>
        <GoogleOAuthProvider clientId="599064016739-pflm9gueit8p5n8avg710m4hpj16kvg6.apps.googleusercontent.com">
          <UserContextProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <RouterProvider router={router}/>
            </LocalizationProvider>
          </UserContextProvider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

import {useContext, useEffect} from "react";
import {GoogleLogin} from "@react-oauth/google";
import {Box, Button, Container, Stack, Typography} from "@mui/material";
import {googleAuth} from "../../service/api";
import UserContext, { api, createAxiosInstance, updateAxios } from "../../context/user";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Auth() {
  const { setCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const {t} = useTranslation()

  const auth = async (googleJwt) => {
    const result = await googleAuth(googleJwt)
    setCurrentUser(result)
    localStorage.setItem('token', result.jwt)
    navigate('/admin')
  }

  return (
    <Container>
      <Stack justifyContent={'center'} alignItems={'center'} sx={{minHeight: '100vh'}}>
        <img src="/mapllo.svg" alt="Mapllo Logo" width={207} height={68}/>
        <Box sx={{marginTop:'60px'}}>
          <GoogleLogin
            onSuccess={credentialResponse => {
              auth(credentialResponse.credential)
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </Box>
      </Stack>
    </Container>
  );
}

import { useEffect, useState } from "react";
import EntityDisplay from "../EntityDisplay";
import { getEvent } from "../../../service/api";
import { Container, Skeleton } from "@mui/material";


export default function AdminEvent({id}) {
    const [entity, setEntity] = useState()


    useEffect(() => {
        getEvent({ id }).then(p => {
            setEntity(p)
        })
    }, [])
    if (!entity) {
        return (<Skeleton />)
    }

    return (
        <Container sx={{ marginTop: '50px' }}>
            <EntityDisplay entity={entity} />
        </Container>
    )
}
import { useTranslation } from "react-i18next";
import DataTable from "../DataTable";
import { useEffect, useState } from "react";
import { createPoster, deletePoster, getPosters, getProfiles } from "../../../../service/api";
import { Alert, Box, Button, Modal, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";


export default function Posters() {
    const { t } = useTranslation();
    const [rows, setRows] = useState()
    const [total, setTotal] = useState(1)
    const [limit, setLimit] = useState(25)
    const [offset, setOffset] = useState(0)
    const [open, setOpen] = useState(false)
    const [snackSuccess, setSnackSuccess] = useState(false)
    const [snackError, setSnackError] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null);

    const refresh = () => {
        getPosters()
            .then(r => {
                setRows(r.map(p => ({ ...p, createdAt: new Date(p.createdAt).toLocaleString() })))
                setTotal(r.length)
            })
    }

    const deleteItem = (row) => {
        deletePoster(row.id)
            .then(() => refresh())
    }

    useEffect(() => {
        refresh()
    }, [offset])

    useEffect(() => {
        if (offset === 0) {
            refresh()
        } else {
            setOffset(0)
        }
    }, [limit])


    const tableConfig = [
        { id: 'id', label: t('admin.posters.table.id'), minWidth: 100 },
        { id: 'name', label: t('admin.posters.table.name'), minWidth: 100 },
        { id: 'description', label: t('admin.posters.table.description'), minWidth: 100 },
    ];
    const toUTCDate = (date) => {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    }
    const create = () => {
        // Get form field values
        const name = document.getElementById('name').value;
        const description = document.getElementById('description').value;
        const date = selectedDate
        const url = document.getElementById('url').value;
        const urlText = document.getElementById('urlText').value;
        const telegram = document.getElementById('telegram').value;
        const whatsapp = document.getElementById('whatsapp').value;
        const instagram = document.getElementById('instagram').value;
        const coords = document.getElementById('coords').value;
        const avatarFile = document.getElementById('avatar').files[0];
      
        // Create FormData object
        const body = {
            'name': name,
            'description': description,
            'date': date,
            'url': url,
            'urlText': urlText,
            'telegram': telegram,
            'whatsapp': whatsapp,
            'instagram': instagram,
            'coords': coords
        }

        console.log(date)
        const formData = new FormData();
        formData.append('body', new Blob([JSON.stringify(body)], {
            type: "application/json"
        }));
          console.log(formData)
        if (avatarFile.type.startsWith('image/')) {
            formData.append('avatar', avatarFile);
        } else if (avatarFile.type.startsWith('video/')) {
            formData.append('video', avatarFile);
        }

        // Send the request
        createPoster(formData)
            .then(data => {
                setSnackSuccess(true)
            })
            .catch(error => {
                setSnackError(true)
            });
    }

    return (
        <>
            <Button onClick={() => setOpen(true)}>Создать</Button>
            <DataTable
                config={tableConfig}
                rows={rows}
                total={total}
                limit={limit}
                setLimit={setLimit}
                page={offset / limit}
                setPage={(page) => setOffset(page * limit)}
                click
                deleteItem={deleteItem}
            />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'} style={{height: '100vh'}}>
                   <Stack style={{backgroundColor: '#fff'}} direction={'column'} padding={'20px'} spacing={'10px'} width={'600px'} borderRadius={'10px'}>
                        <TextField id="name" label="Название" variant="outlined"  />
                        <TextField id="description" label="Описание" variant="outlined" multiline />
                        <DatePicker name="date" onChange={(value) => setSelectedDate(value.format('YYYY-MM-DD'))}/>
                        <TextField id="url" label="Ссылка" variant="outlined" multiline />
                        <TextField id="urlText" label="Вид ссылки" variant="outlined" multiline />
                        <TextField id="telegram" label="Telegram" variant="outlined" multiline />
                        <TextField id="whatsapp" label="Whatsapp" variant="outlined" multiline />
                        <TextField id="instagram" label="Instagram" variant="outlined" multiline />
                        <TextField id="coords" label="Координаты" variant="outlined" multiline />
                        <input id="avatar" type="file" />
                        <Button onClick={create}>Создать</Button>
                   </Stack>
                </Box>
            </Modal>
            <Snackbar anchorOrigin={{vertical:'bottom', horizontal: 'center'}} open={snackSuccess} autoHideDuration={6000} onClose={() => setSnackSuccess(false)}>
                <Alert onClose={() => setSnackSuccess(false)} severity={'success'} sx={{ width: '100%' }}>
                    <Typography>{'Успешно'}</Typography>
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{vertical:'bottom', horizontal: 'center'}} open={snackError} autoHideDuration={6000} onClose={() => setSnackError(false)}>
                <Alert onClose={() => setSnackError(false)} severity={'error'} sx={{ width: '100%' }}>
                    <Typography>{'Ошибка'}</Typography>
                </Alert>
            </Snackbar>
        </>
    )
}
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import styles from './DataTable.module.css'
import { Button, ButtonBase, Container, Modal, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import { fsUrl } from '../../../../constants';
import EntityDisplay from '../../EntityDisplay';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

export default function DataTable({ config, rows, total, limit, setLimit, page, setPage, click, block, deleteItem }) {
  const [activeItem, setActiveItem] = useState()
  const { t } = useTranslation()

  if (!rows) {
    return (
      <Skeleton variant="rectangular" width={'100%'} height={650} />
    )
  }

  const entityInfoModal = () => (
    <Modal
      className={styles.modal}
      open={Boolean(activeItem)}
      onClose={() => setActiveItem(null)}>
      <EntityDisplay entity={activeItem} />
    </Modal>
  );


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {activeItem && entityInfoModal()}
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {config.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                {click && block && (
                  <TableCell
                    align={'center'}
                    style={{ minWidth: 100 }}>
                    {t('admin.table.block_title')}
                  </TableCell>
                )}
                {click && deleteItem && (
                  <TableCell
                    align={'center'}
                    style={{ minWidth: 100 }}>
                    {t('admin.table.delete')}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .map((row) => {
                  return (
                    <TableRow
                      hover role="checkbox" tabIndex={-1} key={row.id}
                      onClick={() => click ? setActiveItem(row) : {}}
                      sx={click ? { cursor: 'pointer' } : {}}>
                      {config.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Typography textAlign={'left'}>
                              {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </Typography>
                          </TableCell>
                        );
                      })}
                      {click && block && (
                        <TableCell align={'center'}>
                          <Typography textAlign={'left'}>
                            <Button onClick={(e) => {
                              e.stopPropagation()
                              block(row)
                            }}>
                              {t(row.deleted ? 'admin.table.unblock' : 'admin.table.block')}
                            </Button>
                          </Typography>
                        </TableCell>
                      )}
                      {click && deleteItem && !row.deleted && (
                        <TableCell align={'center'}>
                          <Typography textAlign={'left'}>
                            <Button onClick={(e) => {
                              e.stopPropagation()
                              deleteItem(row)
                            }}>
                              {t('admin.table.delete')}
                            </Button>
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 100, 500, 1000]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
const mobileInternet = false
const ip = mobileInternet ? '172.20.10.6' : '192.168.1.192'

// export const dev = process.env.NODE_ENV === 'development'
export const dev = false

export const baseUrl = 'https://mapllo.com'

export const authUrl = dev ? 'http://' + ip + ':8010' : (baseUrl + "/auth")
export const wsUrl = dev ? 'http://' + ip + ':8011/ws': (baseUrl + '/api/ws')
export const apiUrl = dev ? 'http://' + ip + ':8011' : (baseUrl + "/api")
export const fsUrl = dev ? 'http://' + ip + ':8010/fs' : (baseUrl + "/fs")

export const sharePosterUrl = baseUrl + 'posters/'
export const shareEventUrl = baseUrl + 'events/'
export const shareUserUrl = baseUrl + 'users/'

import { useTranslation } from "react-i18next";
import { Box, Button, ButtonBase, Container, Divider, Hidden, Modal, Stack, Typography, useMediaQuery } from "@mui/material";
import styles from './Main.module.css'
import Feedback from "./Feedback";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
import privacy_en from '../../pdfs/privacy_en.pdf'
import privacy_ru from '../../pdfs/privacy_ru.pdf'
import terms_en from '../../pdfs/terms_en.pdf'
import terms_ru from '../../pdfs/terms_ru.pdf'
import {Navigate, useNavigate} from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;



export default function Main() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { t, i18n } = useTranslation()
  const [modal, setModal] = useState()
  const [numPages, setNumPages] = useState();
  const [curPage, setCurPage] = useState(1);
  const navigate = useNavigate();


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleDownload = () => {
    const downloadUrl = 'https://mapllo.com/fs/docs/privacy_' + i18n.language + '.pdf';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'privacy.pdf';
    link.click();
  };
  const handleDownloadTerms = () => {
    const downloadUrl = 'https://mapllo.com/fs/docs/terms_' + i18n.language + '.pdf';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'terms.pdf';
    link.click();
  };

  return (
    <Box className={styles.background}>
      <Container maxWidth={"xl"} >
        <Stack justifyContent={"space-between"} minHeight={'100vh'}>
          <Box>
            <Box pt={isSmallScreen ? 4 : '60px'} />
            <Stack direction={isSmallScreen ? 'column' : "row"} alignItems={'center'} spacing={2} component={'header'}>
              <img src="/mapllo.svg" alt="Mapllo Logo" width={207} height={68} />
              <Hidden mdDown>
                <Divider orientation={'vertical'} variant={'middle'} flexItem={true} />
              </Hidden>
              <Box width={'100%'} flexDirection={'row'} display={'flex'} justifyContent={isSmallScreen ? 'center' : 'flex-start'}>
                <Button variant={isSmallScreen ? 'outlined' : 'text'} color={i18n.language === 'en' ? "primary" : "disabled"} onClick={() => i18n.changeLanguage('en')}>ENG</Button>
                {isSmallScreen && <Box style={{ width: '10px' }} />}
                <Button variant={isSmallScreen ? 'outlined' : 'text'} color={i18n.language === 'ru' ? "primary" : "disabled"} onClick={() => i18n.changeLanguage('ru')}>RU</Button>
              </Box>
            </Stack>
            <Box maxWidth={isSmallScreen ? 'auto' : 632} mt={isSmallScreen ? '1rem' : '60px'}>
              <Typography component={'h1'}
                textAlign={isSmallScreen ? "center" : "left"}
                fontSize={'40px'}
                mb={'30px'}
                fontFamily={'Montserrat'}
                lineHeight={1.2}
                fontWeight={'600'}>{t(isSmallScreen ? 'home.title' : 'home.title_long')}</Typography>
              {isSmallScreen && (
                <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'100%'}>
                  <img src="/phones_small.png" style={{ "height": "317px" }} alt="Mapllo Logo" />
                </Box>
              )}
              <Typography ml={'2px'} fontSize={'14px'} mt={'1rem'} whiteSpace={'pre-wrap'} color={'#818195'} fontWeight={'400'}>{t('home.description')}</Typography>
              <Typography fontSize={'14px'} mt={'30px'} mb={'35px'} whiteSpace={'pre-wrap'} color={'#818195'} fontStyle={'italic'} fontWeight={'400'}>{t('home.moto')}</Typography>
            </Box>
            <Stack direction={"row"} spacing={isSmallScreen ? 0 : 4} alignItems={'center'} justifyContent={isSmallScreen ? 'space-between' : 'flex-start'} component={'div'}>
              <img src="/mapllo_app.svg" alt="Mapllo App Logo" width={isSmallScreen ? 57 : 71} height={isSmallScreen ? 57 : 71} />
              <a href={"https://apps.apple.com/app/mapllo-%D0%B7%D0%BD%D0%B0%D0%BA%D0%BE%D0%BC%D1%81%D1%82%D0%B2%D0%B0-%D0%B8-%D0%B4%D0%BE%D1%81%D1%83%D0%B3/id1579691867"} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <img src="/AppStore.svg" alt="App Store Logo" width={isSmallScreen ? 80 : 136} height={isSmallScreen ? 36 : 44} />
              </a>
              <a href={"https://play.google.com/store/apps/details?id=com.production.mapllo"} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <img src="/GooglePlay.svg" alt="Google Play Logo" width={isSmallScreen ? 80 : 136} height={isSmallScreen ? 36 : 44} />
              </a>
              <a href={"https://appgallery.huawei.com/app/C109416847"} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <img src="/huawei.svg" alt="Huawei Logo" width={isSmallScreen ? 80 : 136} height={isSmallScreen ? 36 : 44} />
              </a>
              {/* <Stack direction={'row'} spacing={2}>
              <a href={"https://apps.apple.com/app/mapllo-%D0%B7%D0%BD%D0%B0%D0%BA%D0%BE%D0%BC%D1%81%D1%82%D0%B2%D0%B0-%D0%B8-%D0%B4%D0%BE%D1%81%D1%83%D0%B3/id1579691867"} style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <img src="/AppStore.svg" alt="App Store Logo" width={isSmallScreen? 80 : 136} height={isSmallScreen? 36 : 44}/>
              </a>
              <img src="/GooglePlay.svg" alt="Google Play Logo" width={isSmallScreen? 80 : 136} height={isSmallScreen? 36 : 44}/>

              <img src="/huawei.svg" alt="Huawei Logo" width={isSmallScreen? 80 : 136} height={isSmallScreen? 36 : 44}/>
              </Stack> */}
            </Stack>
          </Box>
          <Divider style={{ marginBottom: '10px', marginTop: '20px', width: isSmallScreen ? '100%' : '632px' }} />
          <Stack component={"footer"} direction={'row'} mb={2} justifyContent={isSmallScreen ? 'space-between' : 'flex-start'}>
            <Box>
              <Typography mt={'2rem'} color={'#818195'} whiteSpace={'pre-wrap'} component={'span'} fontSize={isSmallScreen ? '10px' : '14px'}>
                {t('copyright', { year: new Date().getFullYear() })}{isSmallScreen && '\n'}
              </Typography>

              <Typography color={'#818195'} whiteSpace={'pre-wrap'} component={'span'} fontSize={isSmallScreen ? '10px' : '14px'}>
                {t('rights_reserved')}{' | '}
              </Typography>
              <ButtonBase onClick={() => navigate('/privacy-policy')} >
                <Typography component={'span'} color={'#818195'} fontSize={isSmallScreen ? '10px' : '14px'} textAlign={'left'}>{t('privacy_policy')}</Typography>
              </ButtonBase>
              <Typography color={'#818195'} whiteSpace={'pre-wrap'} component={'span'} fontSize={isSmallScreen ? '10px' : '14px'}>
                {' | '}
              </Typography>
              <ButtonBase onClick={() => setModal(i18n.language == 'en' ? terms_en : terms_ru)} >
                <Typography component={'span'} color={'#818195'} fontSize={isSmallScreen ? '10px' : '14px'} textAlign={'left'}>{t('terms')}</Typography>
              </ButtonBase>
            </Box>
            <Feedback fontSize={'11px'} sx={[isSmallScreen ? { bottom: '30px', right: '20px' } : { right: '100px', top: '80px', position: 'absolute' }]} />
          </Stack>
        </Stack>
      </Container>
      <Modal
        open={!!modal}
        onClose={() => {setModal(null); setCurPage(1);}} style={{ justifyContent: 'center', display: 'flex' }}>
        <Box width={'fit-content'} position={'relative'}>
          <Document file={modal} loading={'Loading'} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
            <Page pageNumber={curPage} renderTextLayer={false} renderAnnotationLayer={false}/>
            <Box position={'absolute'} bottom={'20px'} flexDirection={'row'} display={'flex'} justifyContent={'space-around'} width={'100%'}>
              <ButtonBase onClick={() => setCurPage(Math.max(1, curPage - 1))}><Typography fontSize={40} fontWeight={600} color={'rgba(0,0,0,0.5)'} width='50px'>{'<'}</Typography></ButtonBase>
              <ButtonBase onClick={() => setCurPage(Math.min(numPages, curPage + 1))}><Typography fontSize={40} fontWeight={600} color={'rgba(0,0,0,0.5)'} width='50px'>{'>'}</Typography></ButtonBase>
            </Box>
          </Document>
        </Box>
      </Modal>
    </Box>
  )
}


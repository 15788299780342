import { useTranslation } from "react-i18next";
import DataTable from "../DataTable";
import { useEffect, useState } from "react";
import { blockProfile, blockProfileAuth, createBot, getProfiles } from "../../../../service/api";
import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";


export default function Users() {
    const { t } = useTranslation();
    const [rows, setRows] = useState()
    const [total, setTotal] = useState(1)
    const [limit, setLimit] = useState(25)
    const [offset, setOffset] = useState(0)
    const [open, setOpen] = useState(false)
    const [snackSuccess, setSnackSuccess] = useState(false)
    const [snackError, setSnackError] = useState(false)
    const [gender, setGender] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null);

    const refresh = () => {
        getProfiles({ offset, limit })
            .then(r => {
                setRows(r.profiles.map(p => ({ ...p, createdAt: new Date(p.createdAt).toLocaleString() })))
                setTotal(r.total)
            })
    }

    const switchBlock = (row) => {
        blockProfile({ id: row.id, block: !row.deleted})
        .then(r => blockProfileAuth({ id: row.id, block: !row.deleted}))
        .then(r => refresh());
    }

    useEffect(() => {
        refresh()
    }, [offset])

    useEffect(() => {
        if (offset === 0) {
            refresh()
        } else {
            setOffset(0)
        }
    }, [limit])

    const tableConfig = [
        { id: 'id', label: t('admin.users.table.id'), minWidth: 100 },
        { id: 'userName', label: t('admin.users.table.username'), minWidth: 100 },
        { id: 'name', label: t('admin.users.table.name'), minWidth: 100 },
        { id: 'city', label: t('admin.users.table.city'), minWidth: 100 },
    ];

    const create = () => {
        // // Get form field values
        const name = document.getElementById('name').value;
        const username = document.getElementById('username').value;
        const birthday = selectedDate
        const about = document.getElementById('about').value;
        
        const avatarFile = document.getElementById('avatar').files[0];
      
        // Create FormData object
        const body = {
            'name': name,
            'username': username,
            'birthday': birthday,
            'gender': gender,
            'about': about
        }

        const formData = new FormData();
        formData.append('data', new Blob([JSON.stringify(body)], {
            type: "application/json"
        }));
        formData.append('avatar', avatarFile);
        // if (avatarFile.type.startsWith('image/')) {
        // } else if (avatarFile.type.startsWith('video/')) {
        //     // formData.append('video', avatarFile);
        // }

        // Send the request
        createBot(formData)
            .then(data => {
                setSnackSuccess(true)
            })
            .catch(error => {
                setSnackError(true)
            });
    }
    
    return (
        <>
            <Button onClick={() => setOpen(true)}>Создать</Button>
            <DataTable
                config={tableConfig}
                rows={rows}
                total={total}
                limit={limit}
                setLimit={setLimit}
                page={offset / limit}
                setPage={(page) => setOffset(page * limit)}
                click
                block={(row) => switchBlock(row)}
            />
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box display={'flex'} flex={1} justifyContent={'center'} alignItems={'center'} style={{height: '100vh'}}>
                   <Stack style={{backgroundColor: '#fff'}} direction={'column'} padding={'20px'} spacing={'10px'} width={'600px'} borderRadius={'10px'}>
                        <TextField id="name" label="Имя" variant="outlined"  />
                        <TextField id="username" label="Логин" variant="outlined" multiline />
                        <DatePicker name="birthday" onChange={setSelectedDate} label="Дата рождения"/>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Пол</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="gender"
                                value={gender}
                                label="Пол"
                                onChange={(v) => setGender(v.target.value)}>
                                <MenuItem value={'MALE'}>Мужик</MenuItem>
                                <MenuItem value={'FEMALE'}>Баба</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField id="about" label="О себе" variant="outlined" multiline />
                        <input id="avatar" type="file" />
                        <Button onClick={create}>Создать</Button>
                   </Stack>
                </Box>
            </Modal>
            <Snackbar anchorOrigin={{vertical:'bottom', horizontal: 'center'}} open={snackSuccess} autoHideDuration={6000} onClose={() => setSnackSuccess(false)}>
                <Alert onClose={() => setSnackSuccess(false)} severity={'success'} sx={{ width: '100%' }}>
                    <Typography>{'Успешно'}</Typography>
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{vertical:'bottom', horizontal: 'center'}} open={snackError} autoHideDuration={6000} onClose={() => setSnackError(false)}>
                <Alert onClose={() => setSnackError(false)} severity={'error'} sx={{ width: '100%' }}>
                    <Typography>{'Ошибка'}</Typography>
                </Alert>
            </Snackbar>
        </>
    )
}
import { useParams } from "react-router-dom"
import {useEffect} from "react";

export default function Poster() {
    const {id} = useParams()

    useEffect(() => {
        setTimeout(function() {
            window.location.href = 'https://apps.apple.com/app/mapllo-%D0%B7%D0%BD%D0%B0%D0%BA%D0%BE%D0%BC%D1%81%D1%82%D0%B2%D0%B0-%D0%B8-%D0%B4%D0%BE%D1%81%D1%83%D0%B3/id1579691867'
        }, 500);
    
        // window.location.href = 'com.prod.mapllo://Menu?screen=PosterView&id=' + id;
      }, []);

    return (
        <>
        </>
    )
}

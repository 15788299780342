import {useContext, useEffect } from "react";
import { getOwn } from "../../service/api";
import { Navigate, useNavigate } from "react-router-dom";
import UserContext from "../../context/user";
import styles from './Admin.module.css'
import AdminMenu from "./AdminMenu";

export default function Admin() {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token && !currentUser) {
      reauthenticate()
    }
  }, []);

  const reauthenticate = async () => {
    const own = await getOwn()
    setCurrentUser(own)
  }

  if (!localStorage.getItem('token')) {
    return (<Navigate to={'/admin/auth'} />)
  }

  if (currentUser && !currentUser.admin) {
    return (<Navigate to={'/admin/noaccess'} />)
  }

  if (!currentUser) {
    return (<div></div>)
  }


  return (
    <AdminMenu />
  )
}

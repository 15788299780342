import { Box, Button, Container, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function NoAccess() {
    const navigate = useNavigate();
    const { t } = useTranslation()

    return (
        <Container>
            <Stack justifyContent={'center'} alignItems={'center'} sx={{ minHeight: '100vh' }}>
                <img src="/mapllo.svg" alt="Mapllo Logo" width={207} height={68} />
                <Typography sx={{ marginTop: '60px' }}>{t('admin.no_access')}</Typography>
                <Button onClick={() => {
                    localStorage.removeItem('token')
                    navigate('/admin')
                }}>Logout</Button>
            </Stack>
        </Container>
    )
}

import { createContext, useState } from 'react';

const UserContext = createContext();


export function UserContextProvider(props) {
  const [currentUser, setCurrentUser] = useState(null);

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;

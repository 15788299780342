import { Button, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import styles from './EntityDisplay.module.css'
import { useTranslation } from "react-i18next"
import { fsUrl } from "../../../constants"

export default function EntityDisplay({ entity }) {
    const {t} = useTranslation()
    const activeItem = entity
    return (
        <Container>
            <Paper className={styles.container}>
                <TableContainer sx={{ maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography>{t('admin.table.key')}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{t('admin.table.value')}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(activeItem)
                                .map((activeItemKey) => {
                                    const type = typeof activeItem[activeItemKey]
                                    const v = type === 'object' ? JSON.stringify(activeItem[activeItemKey]) : activeItem[activeItemKey]
                                    const val = v ? v.toString() : ''
                                    const item = val.startsWith(fsUrl + '/IMAGE/') ? (
                                        <Button onClick={() => window.open(val, '_blank')}>
                                            <Typography>{t('admin.table.photo')}</Typography>
                                        </Button>
                                    ) : (Array.isArray(activeItem[activeItemKey]) && activeItem[activeItemKey].length > 0 && activeItem[activeItemKey][0].uri?.startsWith(fsUrl + '/IMAGE/')) ? (
                                        activeItem[activeItemKey].map(i => (
                                            <Button key={i.id} onClick={() => window.open(i.uri, '_blank')}>
                                                <Typography>{t('admin.table.photo')}</Typography>
                                            </Button>
                                        ))
                                    ) : (val.startsWith(fsUrl + '/VIDEO/')) ? (
                                        <Button onClick={() => window.open(val, '_blank')}>
                                            <Typography>{t('admin.table.video')}</Typography>
                                        </Button>
                                    ) : (
                                        <Typography>{val}</Typography>
                                    )
                                    return (
                                        <TableRow
                                            hover role="checkbox" tabIndex={-1}
                                            key={activeItemKey}>
                                            <TableCell >
                                                <Typography>{activeItemKey}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {item}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Container>
    )
}
import { Alert, Box, Button, Container, Divider, FormGroup, Modal, Snackbar, Stack, SvgIcon, TextField, Typography } from "@mui/material";
import FeedbackEnvelope from "../../../icons/FeedbackEnvelope";
import { useTranslation } from "react-i18next";
import styles from './Feedback.module.css'
import { useEffect, useRef, useState } from "react";
import { complain } from "../../../service/api";
import styled from "@emotion/styled";
import {makeStyles} from "@mui/styles";

export default function Feedback(props) {
    const [show, setShow] = useState(false)
    const [snackSuccess, setSnackSuccess] = useState(false)
    const [snackError, setSnackError] = useState(false)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const {t} = useTranslation()


    const sendComplain = () => {
        const fullMessage = name + '\n' + email + '\n' + message
        complain({message: fullMessage})
        .then(r => {
            console.log(r)
            setShow(false)
            setSnackSuccess(true)
            setMessage('')
        })
        .catch(e => {
            console.log(e)
            setSnackError(true)
        })
    }  

const CustomTextField = styled(TextField)({
    // '& .MuiInputBase-root': {
    //   borderRadius: '15px'
    // },
  });
    
    const feedbackModal = (
        <Modal
            className={styles.modal}
            open={show}
            onClose={() => setShow(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Container className={styles.modalBox} maxWidth={'md'}>
                <Typography color={'#818195'} textAlign={'center'}>{t('report_problem')}</Typography>
                <Divider sx={{marginTop: '15px'}}/>
                <FormGroup>
                    <TextField onChange={e => setName(e.target.value)} value={name} InputProps={{disableUnderline:true}} id="name" label={t('report.name')} variant='filled' margin="dense"/>
                    <TextField onChange={e => setEmail(e.target.value)} value={email} InputProps={{disableUnderline:true}} id="email" label={t('report.email')} variant='filled' margin="dense"/>
                    <TextField onChange={e => setMessage(e.target.value)} value={message} InputProps={{disableUnderline:true}} id="message" label={t('report.message')} variant='filled' multiline rows={6} margin="dense"/>
                    <Stack direction={'row'} justifyContent={'center'} margin={'23px'}>
                        <Button onClick={sendComplain} variant="contained" disabled={name.length < 2 || email.length < 8 || message.length < 3}><Typography>{t('report.send')}</Typography></Button>
                        <Box width={'30px'}/>
                        <Button variant="contained" color='gray' onClick={() => setShow(false)}><Typography color={'#fff'}>{t('report.cancel')}</Typography></Button>
                    </Stack>
                </FormGroup>
            </Container>
        </Modal>
    )
    
    const WButton = styled(Button) ({
        borderRadius: '10px',
        paddingLeft: '7px',
        paddingRight: '8px',
        textTransform: 'none'

    })
    return (
        <Box {...props}>
            <WButton variant="contained" disabled={show} onClick={() => setShow(true)}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                    <FeedbackEnvelope />
                    <Typography ml={'6px'} fontSize={props.fontSize ? props.fontSize : '14px'} fontWeight={'500'} color={'#fff'}>{t('write_us')}</Typography>
                </Stack>
            </WButton>
            {show && feedbackModal}
            <Snackbar anchorOrigin={{vertical:'bottom', horizontal: 'center'}} open={snackSuccess} autoHideDuration={6000} onClose={() => setSnackSuccess(false)}>
                <Alert onClose={() => setSnackSuccess(false)} severity={'success'} sx={{ width: '100%' }}>
                    <Typography>{t('report.success')}</Typography>
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{vertical:'bottom', horizontal: 'center'}} open={snackError} autoHideDuration={6000} onClose={() => setSnackError(false)}>
                <Alert onClose={() => setSnackError(false)} severity={'error'} sx={{ width: '100%' }}>
                    <Typography>{t('report.error')}</Typography>
                </Alert>
            </Snackbar>
        </Box>
    )
}
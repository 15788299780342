import { Box, Button, Container, MenuItem, Stack, Tab, Tabs, useMediaQuery, Menu } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom"
import Complains from "./Complains";
import Users from "./Users";
import Posters from "./Posters";
import Events from "./Events";
import AdminUser from "../AdminUser";
import AdminEvent from "../AdminEvent";

const tabParamToIndex = (param) => {
    switch (param) {
        case 'users': return 0;
        case 'events': return 1;
        case 'posters': return 2;
        case 'complains': return 3;
        default: return 0;
    }
}
const indexToTabParam = (index) => {
    switch (index) {
        case 0: return 'users';
        case 1: return 'events';
        case 2: return 'posters';
        case 3: return 'complains';
        default: return '';
    }
}

export default function AdminMenu() {
    const { tabParam, idParam } = useParams()
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [tab, setTab] = useState(tabParamToIndex(tabParam))
    const [menuAnchor, setMenuAnchor] = useState()
    const navigate = useNavigate()
    const { t } = useTranslation()

    useEffect(() => {
        const postfix = idParam ? '/' + idParam : ''
        window.history.pushState(null, '', '/admin/' + indexToTabParam(tab) + postfix)
    }, [tab])


    const TabPanel = ({ value, index, children }) => value == index ? (
        <Box sx={{ marginTop: '80px' }}>
            {children}
        </Box>
    ) : <></>

    const menuOpen = (event) => {
        setMenuAnchor(event.currentTarget)
    }

    const menuClose = (event) => {
        setMenuAnchor(null)
    }

    const onMenuClick = (index) => {
        param.idParam = null
        setTab(index)
        setMenuAnchor(null)
    }

    const dynamicTabs = !isSmallScreen ? (
        <Tabs value={tab} onChange={(e, v) => setTab(v)} aria-label="basic tabs example">
            <Tab label={t('admin.users')} />
            <Tab label={t('admin.events')} />
            <Tab label={t('admin.posters')} />
            <Tab label={t('admin.complains')} />
        </Tabs>
    ) : (
        <Box>
            <Button
                id="basic-button"
                onClick={e => menuOpen(e)}>
                {t('admin.menu')}
            </Button>
            <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={menuClose}>
                <MenuItem onClick={() => onMenuClick(0)}>{t('admin.users')}</MenuItem>
                <MenuItem onClick={() => onMenuClick(1)}>{t('admin.events')}</MenuItem>
                <MenuItem onClick={() => onMenuClick(2)}>{t('admin.posters')}</MenuItem>
                <MenuItem onClick={() => onMenuClick(3)}>{t('admin.complains')}</MenuItem>
            </Menu>
        </Box>
    )

    return (
        <Container sx={{ paddingTop: '20px' }}>
            <Stack direction={'row'} sx={{ marginBottom: '5px', alignItems: 'center' }}>
                <img src="/mapllo.svg" alt="Mapllo Logo" width={207} height={68} style={{ marginRight: '20px' }} />
                {dynamicTabs}
                {!isSmallScreen && <Box sx={{ flexGrow: 1 }} />}
                <Button onClick={() => {
                    localStorage.removeItem('token')
                    navigate('/admin')
                }}>{t('logout')}</Button>
            </Stack>
            {idParam ? (tabParam === 'users' ? (
                <AdminUser id={idParam}/>
            ) : tabParam === 'events' ? (
                <AdminEvent id={idParam}/>
            ) : (
                <>
                </>
            )) : (
                <>
                    <TabPanel value={tab} index={0}>
                        <Users />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Events />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <Posters />
                    </TabPanel>
                    <TabPanel value={tab} index={3}>
                        <Complains />
                    </TabPanel>
                </>
            )}
        </Container>
    )
}
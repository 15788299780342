import { useTranslation } from "react-i18next";
import DataTable from "../DataTable";
import { useEffect, useState } from "react";
import { getComplains } from "../../../../service/api";


export default function Complains() {
    const {t} = useTranslation();
    const [rows, setRows] = useState()
    const [total, setTotal] = useState(1)
    const [limit, setLimit] = useState(25)
    const [offset, setOffset] = useState(0)

    const refresh = () => {
        getComplains({offset, limit})
            .then(r => {
                setRows(r.complains.map(c => ({...c, createdAt:new Date(c.createdAt).toLocaleString()})))
                setTotal(r.total)
            })
    }

    useEffect(() => {
        refresh()
    }, [offset])

    useEffect(() => {
        if (offset === 0) {
            refresh()
        } else {
            setOffset(0)
        }
    }, [limit])

    const tableConfig = [
        { id: 'userId', label: t('admin.complains.table.user_id'), minWidth: 40 },
        { id: 'entity', label: t('admin.complains.table.entity'), minWidth: 20 },
        { id: 'entityId', label: t('admin.complains.table.entity_id'), minWidth: 40 },
        { id: 'message', label: t('admin.complains.table.message'), minWidth: 400 },
        { id: 'createdAt', label: t('admin.complains.table.date'), minWidth: 140 },
    ];


    return (
        <DataTable config={tableConfig} rows={rows} total={total} limit={limit} setLimit={setLimit} page={offset/limit} setPage={(page) => setOffset(page * limit)}/>
    )
}